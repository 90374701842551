import React, { memo } from 'react';
import styled from 'styled-components';
import Image from './Image';

import type {
  BlockMediaCollectionFragment,
  MediaItemFragment,
} from '../schemas/api';

import useInview from '../hooks/useInview';

import TYPO from '../styles/typography';
import { Grid, columns } from '../styles/grid';
import { spacing } from '../styles/blocks';
import Heading from './Heading';
import COLORS from '../styles/colors';
import { Tag } from '../styles/tag';
import LinkJSX from './Link';

type ContainerProps = {
  $spacing: string;
};
const Container = styled(Grid)<ContainerProps>`
  ${(props) => spacing(props.$spacing || '')}
  position:relative;
`;

const Content = styled.div`
  ${columns(1, 12)}
  padding-top: 48px;
  margin-top: 48px;
  display: grid;
  grid-template-columns: repeat(1, [col-start] 1fr);
  gap: 3px;

  @media (min-width: 640px) {
    grid-template-columns: repeat(2, [col-start] 1fr);
  }
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, [col-start] 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, [col-start] 1fr);
  }
`;

const IntegrationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 12px;
  border-radius: 3px;
  border: 1px solid transparent;
  height: 100%;

  @media (min-width: 768px) {
    padding: 32px 32px;
  }

  background-color: ${COLORS.white.css};

  &[data-interactive='true']:hover {
    background-color: ${COLORS.brand.light.mix(COLORS.white, 0.85) as string};
  }
`;

const IntegrationContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  & > h5 {
    display: flex;
    gap: 12px;
    align-items: center;
    margin: 0;
    ${TYPO.h3}
  }
  & > p {
    margin: 0;
    ${TYPO.p2}
  }
`;

type IntegrationProps = {
  data: MediaItemFragment;
};
const Integration = ({ data }: IntegrationProps) => {
  return (
    <LinkJSX link={data.cta}>
      <IntegrationContainer data-interactive={data.cta ? true : undefined}>
        <Image
          src={data.media?.url}
          height={data.media.height}
          width={data.media.width}
          alt={data.heading}
          style={{ width: 'auto', height: 42 }}
        />
        <IntegrationContent>
          <h5>
            {data.heading}
            {data.tag && (
              <Tag data-variant={data.tagStyle?.toLowerCase()}>{data.tag}</Tag>
            )}
          </h5>
          <p>{data.content}</p>
        </IntegrationContent>
      </IntegrationContainer>
    </LinkJSX>
  );
};

type BlockImageGridProps = {
  className?: string;
  data: BlockMediaCollectionFragment;
};

function BlockIntegations({ className, data }: BlockImageGridProps) {
  const [inViewRef, inView] = useInview({});
  return (
    <Container $spacing={data.spacing} className={className}>
      {data.heading && (
        <Heading
          heading={data.heading}
          byline={data.description}
          show={inView}
          ref={inViewRef}
        />
      )}
      <Content>
        {data?.mediaCollection.items.map((item) => (
          <Integration data={item} key={item.sys.id} />
        ))}
      </Content>
    </Container>
  );
}

export default memo(BlockIntegations);
