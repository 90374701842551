import React, { memo } from 'react';
import styled from 'styled-components';

import type { BlockSignupFragment } from '../schemas/api';

import useInview from '../hooks/useInview';

import { staggerChildrenFadeTranslateIn } from '../styles/animations';
import { Grid, columns } from '../styles/grid';

import SubscribeSection from './SubscribeSection';

const Container = styled(Grid)`
  margin-top: 80px;
`;

type ContentProps = { $show: boolean };
const Content = styled.div<ContentProps>`
  ${columns(1, 12)};
  gap: 24px;
  width: 100%;
  ${(props) => staggerChildrenFadeTranslateIn(props.$show)}
`;

type BlockSignupProps = {
  data: BlockSignupFragment;
};

function BlockSignup({ data }: BlockSignupProps) {
  const [inViewRef, inView] = useInview({});
  return (
    <Container>
      <Content $show={inView} ref={inViewRef}>
        <SubscribeSection
          heading={data.heading}
          subHeading={data.byline}
          cta={data.cta}
        />
      </Content>
    </Container>
  );
}

export default memo(BlockSignup);
