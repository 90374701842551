import React, { ForwardedRef, forwardRef, memo, useState } from 'react';
import styled from 'styled-components';

import { useInView } from 'react-intersection-observer';

import { useMediaQuery } from 'usehooks-ts';
import dynamic from 'next/dynamic';
import type {
  BlockMediaCollectionFragment,
  MediaItemFragment,
} from '../schemas/api';

import TYPO from '../styles/typography';
import { Grid, columns } from '../styles/grid';

import MarkdownInline from './MarkdownInline';
import CTAButtons from './CTAButtons';
import COLORS from '../styles/colors';

import Heading from './Heading';
import Image from './Image';
import PlayButton from './PlayButton';
import LinkJSX from './Link';
import { fadeIn } from '../styles/animations';

const ReactPlayer = dynamic(() => import('react-player'), { ssr: false });
const ModalVideo = dynamic(() => import('./ModalVideo'), { ssr: false });

const DESCRIPTION_HEIGHT = 86;
const DESCRIPTION_HEIGHT_MOBILE = 60;
const TOP_PADDING = 36;
const ROW_SPACING = 40;
const TRANSLATE_Y = 80;
const COLUMNS = 18;
const MAX_WIDTH = 1280;
const LOGO_SCALE = 0.2;

const TRANSITION = '0.7s cubic-bezier(0.72, 0, 0.12, 1)';

const Container = styled(Grid)`
  padding: 52px 12px 6px;

  @media (min-width: 768px) {
    padding: 52px 12px 40px;
  }
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 24px;
`;

const HeadingSection = styled.div`
  padding: 0 12px;
  ${columns(1, 12)};

  @media (min-width: 768px) {
    ${columns(2, 10)};
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  transform-origin: bottom center;

  transform: translateY(0%) scale(1);
  transition: transform ${TRANSITION};

  will-change: transform;

  &[data-in-view='true'] {
    transform: translateY(15%) scale(0.95);
  }
`;

const Items = styled.div`
  aspect-ratio: 1 / 1;
  transition: transform ${TRANSITION}, clip-path ${TRANSITION};
  position: relative;
  width: 100%;
  will-change: aspect-ratio, transform;

  max-height: ${MAX_WIDTH / (16 / 12)}px;

  @media (min-width: 1110px) {
    aspect-ratio: 2 / 1;
    max-height: ${MAX_WIDTH / (16 / 10)}px;
  }

  @media (min-width: 768px) {
    aspect-ratio: 16 / 11;
    max-height: ${MAX_WIDTH / (16 / 10)}px;
  }
`;

const BackgroundImage = styled(Image)`
  object-position: center top;
  object-fit: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;

  grid-column: 2 / ${COLUMNS};

  opacity: 0;
  visibility: hidden;
  scale: 1.1;
  transition: opacity 0.3s ease 0s, scale 0.3s ease-in-out 0s,
    visibility 1ms ease 0.3s;

  &[data-active='true'] {
    opacity: 1;
    visibility: visible;
    scale: 1;
    transition: opacity 0.3s ease 0s, scale 0.3s ease-in-out 0s;
  }
`;

const Backgrounds = styled.div`
  background: ${COLORS.black.css};
  grid-template-columns: repeat(${COLUMNS}, 1fr);
  display: grid;
  margin: 0px auto;
  position: relative;
  width: 100%;
  height: 100%;

  transition: transform ${TRANSITION}, width ${TRANSITION};
  overflow: hidden;
  opacity: 1;
  will-change: scale, width;

  @media (min-width: 1440px) {
    width: 100%;
  }

  @media (min-width: 768px) {
    scale: 1.2375;
  }
`;

const Videos = styled.div`
  grid-template-columns: repeat(${COLUMNS}, 1fr);
  display: grid;
  max-width: ${MAX_WIDTH}px;
  margin: 0px auto;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 50%;
  transform-origin: center top;

  width: 100%;
  transition: transform ${TRANSITION};
`;

const Media = styled.div`
  border: 2px solid ${COLORS.brand.light.css};
  background: ${COLORS.white.css};
  border-radius: 9px;

  overflow: hidden;
  position: relative;
  height: 100%;
  aspect-ratio: 16 / 9;
  display: block;

  & > div {
    object-fit: cover;
    display: block;
    position: relative;
    width: 100%;
    pointer-events: none;
    z-index: 2;

    & video {
      object-position: top;
      object-fit: cover;
      scale: 1.001;
    }
  }
`;

const MediaItem = styled.div`
  margin: 0px auto;

  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: opacity 0.3s ease 0s, transform 0.3s ease-in-out 0s;
  pointer-events: all;
  grid-column: 1 / -1;
  width: calc(100% - 32px);

  opacity: 0;
  visibility: hidden;
  transform: scale(0.95);
  transition: opacity 0.3s ease 0s, transform 0.3s ease-in-out 0s,
    visibility 1ms ease 0.3s;

  &[data-active='true'] {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transition: opacity 0.3s ease 0s, transform 0.3s ease-in-out 0s;
  }

  @media (min-width: 1130px) {
    left: 24px;
    width: calc(100% - 48px);
  }

  @media (min-width: 768px) {
    left: 16px;
    grid-column: 2 / 18;
    margin: 0px;
    position: absolute;
  }
`;

const PlaceholderImage = styled(Image)`
  object-position: top;
  display: block;
  object-fit: cover;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
`;

const VideoContainer = styled.div`
  max-width: 100%;
  margin: 0px auto;
  width: 100%;
  position: relative;

  @media (min-width: 768px) {
    max-width: 100%;
  }

  @media (min-width: 1130px) {
    max-width: 100%;
  }
`;

const ItemHeading = styled.div`
  color: white;
  display: block;

  margin-bottom: 0px;
  text-align: center;
  text-wrap: balance;
  transform: translateY(-4px);
  max-width: 90%;

  color: white;
  font-size: 18px;
  line-height: 140%;
  font-weight: 600;
  letter-spacing: -0.03em;
`;

const ItemLabel = styled.div`
  color: white;
  display: block;

  margin-bottom: 0px;
  text-align: center;
  text-wrap: balance;
  transform: translateY(-4px);
  max-width: 100%;

  color: white;
  font-size: 14px;
  line-height: 140%;
  font-weight: 400;
  letter-spacing: -0.03em;

  @media (min-width: 1130px) {
    font-size: 21px;
  }

  @media (min-width: 768px) {
    max-width: 90%;
    font-weight: 500;
    font-size: 16px;
  }

  @media (min-width: 480px) {
    font-size: 15px;
  }
`;

const ItemContent = styled.div`
  grid-template-columns: repeat(${COLUMNS}, 1fr);
  display: grid;
  max-width: ${MAX_WIDTH}px;
  margin: 0px auto;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, 0);
`;

const ItemInner = styled.div`
  padding: 21px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media (min-width: 1130px) {
    padding: 36px 36px 48px;
    gap: 24px;
  }

  @media (min-width: 768px) {
    padding: 32px 32px 48px;
    gap: 12px;
    grid-column: 2 / ${COLUMNS};
    justify-content: flex-end;
  }

  @media (min-width: 480px) {
    padding: 30px;
  }

  flex-wrap: wrap;
  gap: 9px;
  width: 100%;
  position: relative;
  transition: opacity ${TRANSITION};
  grid-column: 1 / -1;
`;

const CarouselContainer = styled.section`
  ${columns(1, 12)}
  margin-top: 84px;
  position: relative;
  display: block;
  flex: 1 0 100%;

  scroll-snap-align: center;

  ${Items} {
    @media (min-width: 768px) {
      clip-path: inset(0% 0px);
    }
    clip-path: inset(0px 0px round 6px);
    transform: translateY(70px);
  }
  ${Backgrounds} {
    scale: 1.2;
  }
  ${Videos} {
    transform: translate(-50%, 0px) scale(0.95);
    @media (min-width: 768px) {
      transform: translate(-50%, -${TRANSLATE_Y}px) scale(1.05);
    }
    @media (min-width: 1280px) {
      transform: translate(-50%, -${TRANSLATE_Y}px) scale(1.12);
    }
  }
  ${Media} {
    box-shadow: 0px 0px 48px ${COLORS.brand.regular.opacity(0.1)};
    @media (min-width: 768px) {
      box-shadow: 0px 0px 80px ${COLORS.brand.regular.opacity(0.1)};
    }
  }
  ${ItemInner} {
    opacity: 0;
  }

  &[data-in-view='true'] {
    ${Items} {
      @media (min-width: 768px) {
        clip-path: inset(
          0px calc(50vw - min(${MAX_WIDTH / 2}px, 50vw)) round 12px
        );
      }
      clip-path: inset(0px 0px round 6px);
      transform: translateY(0px);
    }
    ${Backgrounds} {
      scale: 1.3;
    }
    ${Videos} {
      transform: translate(
        -50%,
        ${TOP_PADDING + DESCRIPTION_HEIGHT_MOBILE + ROW_SPACING}px
      );
      @media (min-width: 480px) {
        transform: translate(
          -50%,
          ${TOP_PADDING + DESCRIPTION_HEIGHT + ROW_SPACING}px
        );
      }
      @media (min-width: 768px) {
        transform: translate(-50%, ${TOP_PADDING}px);
      }
    }
    ${ItemInner} {
      opacity: 1;
    }
  }
`;

const MobileCarouselContainer = styled.div`
  ${columns(1, 12)}
  scroll-snap-type: x mandatory;
  display: flex;
  overflow: auto hidden;

  padding: 48px 12px 0;
  ${CarouselContainer} {
    flex: 0 0 calc(100% - 60px);
    margin: 0 12px;
  }
`;

const Navbar = styled.nav`
  display: flex;
  gap: 3px;
  border-radius: 6px;
  height: 48px;
  border: 1px solid white;
  padding: 3px 3px;
`;

type NavItemProps = {
  $active: boolean;
};

const NavItem = styled.button<NavItemProps>`
  ${TYPO.p1};
  font-weight: 500;
  letter-spacing: 0.01em;
  background: ${(props) => (props.$active ? '#fff' : 'transparent')};
  color: ${(props) => (props.$active ? '#000' : '#fff')};
  border-radius: 3px;
  padding: 3px 12px;
  &:hover {
    background: ${(props) =>
      props.$active ? '#fff' : 'rgba(255,255,255,0.1)'};
  }

  @media (min-width: 1130px) {
    padding: 3px 24px;
  }
`;

const LogosContainer = styled.div`
  padding: 30px 12px 12px;
  align-items: center;
  display: flex;
  gap: 18px;

  mask-image: linear-gradient(
    90deg,
    transparent 0%,
    #000 10%,
    #000 90%,
    transparent 100%
  );

  max-width: 768px;

  @media (min-width: 780px) {
    gap: 30px;
  }

  animation: ${fadeIn} 1s ease-out;
  animation-fill-mode: both;
`;

const LogoLink = styled(LinkJSX)`
  flex: 1;
  display: flex;

  &:hover {
    opacity: 0.7;
  }

  flex: calc(1 - var(--transform));

  &:first-child,
  &:last-child {
    display: none;
  }
  @media (min-width: 480px) {
    &:first-child,
    &:last-child {
      display: flex;
    }
  }
`;

const LogoImage = styled(Image)`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

type DisplayVideoItemProps = React.HTMLAttributes<HTMLDivElement> & {
  data: MediaItemFragment;
  active: boolean;
  demoVideo?: string;
};

function DisplayVideoItem({
  data,
  active,
  demoVideo,
  ...props
}: DisplayVideoItemProps) {
  const [show, setShow] = useState(false);

  return (
    <MediaItem {...props} data-active={active}>
      {demoVideo && (
        <ModalVideo
          open={show}
          setOpen={setShow}
          url={demoVideo}
          width="100%"
        />
      )}
      <VideoContainer>
        {demoVideo && <PlayButton onClick={() => setShow(true)} />}
        <Media>
          <ReactPlayer
            data-video-container
            url={`${data.media.url}#t=0.5`}
            width="100%"
            height="100%"
            playing={active}
            muted
            loop
            controls={false}
            playsinline
            config={{
              file: {
                attributes: {
                  poster: data.posterImage?.url,
                  playsInline: true,
                  webkitPlaysinline: true,
                },
              },
            }}
          />
          {data.posterImage && (
            <PlaceholderImage
              src={data.posterImage.url}
              width={data.posterImage.width}
              height={data.posterImage.height}
              placeholder="blur"
              priority={active}
              alt={`placeholder ${data.heading}`}
            />
          )}
        </Media>
      </VideoContainer>
    </MediaItem>
  );
}

type CarouselProps = {
  items: MediaItemFragment[];
  inView: boolean;
  demoVideo?: string;
};

const Carousel = forwardRef(
  (
    { items, inView, demoVideo }: CarouselProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const [active, setActive] = useState(0);

    return (
      <CarouselContainer ref={ref} data-in-view={inView}>
        <Items>
          <Backgrounds>
            {items.map((d, i) => {
              if (!d.background) return null;
              return (
                <BackgroundImage
                  alt={d.background.title || d.heading}
                  key={d.sys.id}
                  src={d.background.url}
                  width={d.background.width}
                  height={d.background.height}
                  data-active={active === i}
                  priority={active === i}
                  aria-current={active === i}
                />
              );
            })}
          </Backgrounds>
          <ItemContent>
            <ItemInner role="region" aria-roledescription="carousel">
              {items.map((d, i) => {
                return (
                  <ItemLabel
                    key={d.sys.id}
                    style={{ display: active === i ? 'block' : 'none' }}
                    aria-current={active === i}
                    role="group"
                    aria-roledescription="Slide"
                  >
                    <MarkdownInline>{d.content}</MarkdownInline>
                  </ItemLabel>
                );
              })}
              <Navbar>
                {items.map((d, i) => {
                  return (
                    <NavItem
                      key={d.sys.id}
                      $active={active === i}
                      onClick={() => setActive(i)}
                      aria-current={active === i}
                    >
                      {d.heading}
                    </NavItem>
                  );
                })}
              </Navbar>
            </ItemInner>
          </ItemContent>
        </Items>
        <Videos role="region" aria-roledescription="carousel">
          {items.map((d, i) => {
            return (
              <DisplayVideoItem
                data={d}
                key={d.sys.id}
                active={active === i}
                demoVideo={demoVideo}
                role="group"
                aria-roledescription="Slide"
                aria-current={active === i}
              />
            );
          })}
        </Videos>
      </CarouselContainer>
    );
  }
);

Carousel.displayName = 'Carousel';

type MobileSlideProps = {
  data: MediaItemFragment;
  sectionInView: boolean;
  demoVideo?: string;
};
const MobileSlide = ({ data, sectionInView, demoVideo }: MobileSlideProps) => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    rootMargin: '0px 30% 30% 30%',
  });

  return (
    <CarouselContainer
      data-section-in-view={sectionInView}
      data-in-view
      data-current-slide={inView}
      ref={ref}
    >
      <Items>
        <Backgrounds>
          <BackgroundImage
            alt={data.background.title || data.heading}
            src={data.background.url}
            width={data.background.width}
            height={data.background.height}
            data-active
            aria-selected
          />
        </Backgrounds>
        <ItemContent>
          <ItemInner>
            <ItemHeading>{data.heading}</ItemHeading>
            <ItemLabel aria-selected>
              <MarkdownInline>{data.content}</MarkdownInline>
            </ItemLabel>
          </ItemInner>
        </ItemContent>
      </Items>
      <Videos>
        <DisplayVideoItem
          data={data}
          active
          aria-selected
          demoVideo={demoVideo}
        />
      </Videos>
    </CarouselContainer>
  );
};

const MobileCarousel = forwardRef(
  (
    { items, inView, demoVideo }: CarouselProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <MobileCarouselContainer ref={ref}>
        {items.map((d) => {
          return (
            <MobileSlide
              data={d}
              sectionInView={inView}
              key={d.sys.id}
              demoVideo={demoVideo}
            />
          );
        })}
      </MobileCarouselContainer>
    );
  }
);

MobileCarousel.displayName = 'Carousel';

type BlockTeamCarouselProps = {
  data: BlockMediaCollectionFragment;
};

function BlockZoomCarousel({ data }: BlockTeamCarouselProps) {
  const isDesktop = useMediaQuery('(min-width: 768px)', {
    initializeWithValue: false,
    defaultValue: true,
  });
  const { ref, inView, entry } = useInView({
    rootMargin: '0px 0px -90% 0px',
    threshold: 0.1,
  });

  const itemInView = inView || entry?.boundingClientRect?.y < 0 || false;

  return (
    <React.Fragment>
      <Container ref={ref}>
        <HeadingSection data-in-view={isDesktop ? itemInView : false}>
          <StyledHeading heading={data.heading} byline={data.byline} show />
          {!!data.actions?.items.length && (
            <CTAButtons links={data.actions.items} />
          )}
          {data.logos?.items?.length > 0 && (
            <LogosContainer>
              {data.logos.items.map((d, i) => {
                const mid = Math.floor(data.logos.items.length / 2);
                const transform = Math.abs(i - mid);
                return (
                  <LogoLink
                    link={{ type: 'external', url: d.url }}
                    key={d.name}
                    style={
                      {
                        '--transform': transform * LOGO_SCALE,
                      } as React.CSSProperties
                    }
                  >
                    <LogoImage
                      src={d.logo.url}
                      width={d.logo.width}
                      height={d.logo.height}
                      alt={d.name}
                    />
                  </LogoLink>
                );
              })}
            </LogosContainer>
          )}
        </HeadingSection>
      </Container>
      {isDesktop ? (
        <Carousel
          items={data.mediaCollection.items}
          inView={itemInView}
          demoVideo={data.extra.demo}
          key="desktop"
        />
      ) : (
        <MobileCarousel
          items={data.mediaCollection.items}
          inView={itemInView}
          demoVideo={data.extra.demo}
          key="mobile"
        />
      )}
    </React.Fragment>
  );
}

export default memo(BlockZoomCarousel);
