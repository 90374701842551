export enum BlockType {
  BLOCK_COPY = 'BlockCopy',
  BLOCK_LOGO = 'BlockLogo',
  BLOCK_TEAM_MEMBER = 'BlockTeamMember',
  BLOCK_BENEFIT = 'BlockBenefit',
  BLOCK_BLOG_POST = 'BlockBlogPost',
  BLOCK_MEDIA_COLLECTION = 'BlockMediaCollection',
  BLOCK_CONTENT = 'BlockContent',
  BLOCK_IMAGE = 'BlockImage',
  BLOCK_QUOTE = 'BlockQuote',
  BLOCK_JOB_OPENINGS = 'BlockJobOpenings',
  BLOCK_CTA = 'BlockCta',
  BLOCK_SCROLLING_FEATURES = 'BlockScrollingFeatures',
  BLOCK_TEXT_TYPEWRITER = 'BlockTextTypewriter',
  BLOCK_SPLASH = 'BlockSplash',
  BLOCK_SPLASH_VIDEO = 'BlockSplashVideo',
  BLOCK_LOCATIONS = 'BlockLocation',
  BLOCK_WITH_WITHOUT_FLOW = 'BlockWithWithoutFlow',
  BLOCK_STATS_COUNTER = 'BlockStatsCounter',
  BLOCK_FAQ = 'BlockFaq',
  BLOCK_LOGOS_AND_TESTIMONIALS = 'BlockLogoAndTestimonials',
  BLOCK_TABLE_COMPETITORS = 'BlockTableCompetitors',
  BLOCK_PRE_FOOTER = 'BlockPreFooter',
  BLOCK_PRICING = 'BlockPricing',
  BLOCK_DEMO_FORM = 'Release',
  BLOCK_AI = 'FlowProject',
  BLOCK_SIGNUP = 'BlockSignup',
}

export enum BlockCopyStyle {
  REQ_SPLASH = 'RequirementsSplash',
  VERSION_CONTROL_CHART = 'VersionControlChart',
  GRAPH_NETWORK = 'GraphNetwork',
  OTHER = '?',
}

export enum BlockBenefitStyle {
  TEAM_PAGE = 'teampage',
  ICONS = 'icons',
  OTHER = '?',
}

export enum BlockMediaCollectionStyle {
  ICONS = 'Icons',
  FEATURES = 'Features',
  ALTERNATING_SMALL_IMAGES = 'Alternating Small Images',
  SMALL_IMAGE_SIDE = 'Small Image Side',
  ROLE_CAROUSEL = 'Roles Carousel',
  ANIMATED_FEATURES = 'Animated Features',
  ZOOM_CAROUSEL = 'Zoom Carousel',
  HORIZONTAL_SCROLL_CAROUSEL = 'Horizontal Scroll Carousel',
  LOGOS = 'Logos',
  TWO_COLUMNS_SIDE = 'Two Columns Side',
  INTEGRATIONS = 'Integrations',
  OTHER = '?',
}

export enum MediaAnimation {
  COLLABORATION = 'Collaboration',
  SYNC = 'Sync',
  CONTINUOUS_INTEGRATION = 'Continuous Integration',
  INTEGRATED = 'Integrated',
  INSIGHTS = 'Insights',
  ACCESSIBLE = 'Accessible',
}

export enum BlockContentStyle {
  TWO_COLUMN = 'two-column',
  ONE_COLUMN = 'one-column',
  OTHER = '?',
}

export enum BlockImageStyle {
  FULLSCREEN = 'Fullscreen',
  LARGE_HEADING = 'Large Heading',
  SIMPLE = 'Simple',
  OTHER = '?',
}

export enum BlockLogosStyle {
  GRID = 'grid',
  LOGOS = 'logos',
  INTEGRATIONS = 'integrations',
}
