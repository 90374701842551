import React, { ForwardedRef, forwardRef } from 'react';

import styled from 'styled-components';
import MailchimpForm, { HiddenInput, Input } from './MailchimpForm';

const StyledMailchimpForm = styled(MailchimpForm)`
  max-width: 420px;
  gap: 3px;
`;

type HandbookMailchimpFormProps = {
  title?: string;
};

function HandbookMailchimpForm({ title }, ref: ForwardedRef<HTMLFormElement>) {
  return (
    <StyledMailchimpForm ref={ref}>
      <Input type="email" name="EMAIL" placeholder="Your email" required />
      <HiddenInput type="text" aria-hidden="true" />
      {title && (
        <HiddenInput type="text" name="FROM" value={title} aria-hidden="true" />
      )}
    </StyledMailchimpForm>
  );
}

export default forwardRef<HTMLFormElement, HandbookMailchimpFormProps>(
  HandbookMailchimpForm
);
